/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//Dark Themed
// require('prismjs/themes/prism-okaidia.css');
require('prismjs/themes/prism-tomorrow.css');
//Light Theme
// require('prismjs/themes/prism.css');
// require('prismjs/plugins/line-numbers/prism-line-numbers.css');
exports.onServiceWorkerUpdateReady = () => window.location.reload();
