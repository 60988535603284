module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-166891305-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-remark-google-analytics-track-links/gatsby-browser.js'),
      options: {"plugins":[],"allowFollowLinks":false,"className":"siteLink","localLinkMatch":false,"gaOptions":{"internalLinkTitle":"Blog Link","externalLinkTitle":"Blog Link","eventAction":"click","eventLabel":false},"rel":"noopener noreferrer","runInDev":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"wrapperStyle":"margin-bottom: 2rem; margin-top: 2rem;","linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Coding Walrus","short_name":"Coding Walrus","description":"Snippets and slices of code ripe for fishing.","background_color":"#f6f6f6","theme_color":"#f6f6f6","start_url":"/","display":"standalone","icon":"static/walrus-3d-roumd.svg","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ab20be19527b4b7af7793d3ec31a24cc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
